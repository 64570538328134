import { FC } from 'react'
import { ReactComponent as LeftChevron } from '../images/Left_Chevron.svg'
import { ReactComponent as RightChevron } from '../images/Right_Chevron.svg'

import Style from '../style/gallery.module.sass'

interface IGallery {
  img: string
  title: string
  caption: string
}

interface GalleryProps {
  gallery: IGallery[]
  index: number
  setIndex: (index: number) => void
  onClose: () => void
}

const Gallery: FC<GalleryProps> = (props) => {
  const onClickBackground = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if ((e.target as HTMLDivElement).id === 'gallery') props.onClose()
  }

  return (
    <div id='gallery' className={Style.gallery} onClick={onClickBackground}>
      <button title='Close' className={Style.close} onClick={props.onClose}>
        ✕
      </button>

      <div className={Style.container}>
        <div className={Style.imageHolder}>
          <img src={props.gallery[props.index].img} alt={props.gallery[props.index].title} />
        </div>
        <div className={Style.info}>
          <h3>{props.gallery[props.index].title}</h3>
          <p dangerouslySetInnerHTML={{ __html: props.gallery[props.index].caption }} />
        </div>
      </div>

      <div className={Style.nav}>
        {props.index > 0 ? (
          <button className={Style.previous} title='Previous' onClick={() => props.setIndex(props.index - 1)}>
            <LeftChevron />
          </button>
        ) : (
          <div className={Style.blocker} />
        )}

        <div className={Style.dots}>
          {props.gallery.map((g, i) => (
            <button key={i} disabled={i === props.index} onClick={() => props.setIndex(i)}></button>
          ))}
        </div>

        {props.index < props.gallery.length - 1 ? (
          <button className={Style.next} title='Next' onClick={() => props.setIndex(props.index + 1)}>
            <RightChevron />
          </button>
        ) : (
          <div className={Style.blocker} />
        )}
      </div>
    </div>
  )
}

export default Gallery
