import BackgroundImage from '../images/greenwich_point/GreenwichPoint.jpg'
import MapImage from '../images/greenwich_point/GreenwichPoint_Map.jpg'
import Pontoon from '../images/greenwich_point/GW-Benefits_Covered-pontoon.jpg'
import ExistingWharf from '../images/greenwich_point/GW-Benefits_Existing-wharf.jpg'
import BusShelter from '../images/greenwich_point/GW-Benefits_Relocated-bus-shelter.jpg'
import Gangway from '../images/greenwich_point/GW-Benefits_Uncovered-gangway.jpg'
import LiftAndStairs from '../images/greenwich_point/GW-Benefits_Lifts-and-stairs.jpg'
import Transport from '../images/greenwich_point/GW-Benefits_Transport.jpg'
import Waterside from '../images/greenwich_point/GW-Benefits_Waterside.jpg'
import WorkingHours from '../images/greenwich_point/GW-Benefits_Working-hours-and-noise.jpg'

import View1_Before from '../images/greenwich_point/GreenwichPoint_View1_Before.jpg'
import View1_After from '../images/greenwich_point/GreenwichPoint_View1_After.jpg'
import View2_Before from '../images/greenwich_point/GreenwichPoint_View2_Before.jpg'
import View2_After from '../images/greenwich_point/GreenwichPoint_View2_After.jpg'
import View3_Before from '../images/greenwich_point/GreenwichPoint_View3_Before.jpg'
import View3_After from '../images/greenwich_point/GreenwichPoint_View3_After.jpg'
import View4_Before from '../images/greenwich_point/GreenwichPoint_View4_Before.jpg'
import View4_After from '../images/greenwich_point/GreenwichPoint_View4_After.jpg'

import { IDataProvider } from '../models/models'

const greenwichPointData: IDataProvider = {
  title: 'Greenwich Point Wharf Upgrade',
  wharf: 'new Greenwich Point Wharf',
  wharfHeader: 'Project Benefits',
  wharfDesc: 'The upgrade would provide',
  slug: 'greenwich_point',
  imageHeight: 2200,
  info: 'Following feedback from the community in early 2021, the Review of Environmental Factors has been prepared and is on display for community feedback.',
  backgroundImage: BackgroundImage,
  mapImage: MapImage,
  sliders: [
    {
      img1: View1_Before,
      img2: View1_After,
      title: 'View 1',
      caption:
        "Artist's impression of the proposed Greenwich Point Wharf. View of proposal looking south from Lower Serpentine Road.",
      x: 79,
      y: 33,
    },
    {
      img1: View2_Before,
      img2: View2_After,
      title: 'View 2',
      caption:
        "Artist's impression of the proposed Greenwich Point Wharf. View of proposal from the Mary Carlson Park.",
      x: 91,
      y: 54,
    },
    {
      img1: View3_Before,
      img2: View3_After,
      title: 'View 3',
      caption:
        "Artist's impression of the proposed Greenwich Point Wharf. View of the proposal from the former nineteenth century wharf looking north west.",
      x: 74,
      y: 86,
    },
    {
      img1: View4_Before,
      img2: View4_After,
      title: 'View 4',
      caption:
        "Artist's impression of the proposed Greenwich Point Wharf. View of proposal from the water looking north east.",
      x: 15,
      y: 91,
    },
  ],
  gallery: [
    {
      img: Pontoon,
      title: 'Covered Pontoon',
      caption:
        'The waiting area would have a curved roof, seating and glass weather protection panels to provide passengers with a comfortable place to wait for their ferry on the pontoon.',
      x: 19,
      y: 63,
      env: false,
    },
    {
      img: ExistingWharf,
      title: 'Existing Wharf',
      caption:
        'The existing wharf structure would be removed. The existing stairs would be replaced with new accessible compliant stairs.',
      x: 57,
      y: 66,
      env: false,
    },
    {
      img: BusShelter,
      title: 'Relocated Bus Shelter',
      caption: 'The existing bus shelter on Lower Serpentine Road would be relocated closer to the new access points.',
      x: 84,
      y: 43,
      env: false,
    },
    {
      img: Gangway,
      title: 'Uncovered Gangway',
      caption: `The pontoon would be connected to land by an uncovered gangway and jetty. The structure would be accessible by a new lift and stairs.
                <p>New bicycle hoops would be installed nearby.</p>`,
      x: 38,
      y: 74,
      env: false,
    },
    {
      img: WorkingHours,
      title: 'Working Hours and Noise',
      caption: `<p>Our standard work hours would be between:
                <ul style="list-style-type:none">
                  <li>- 7am and 6pm Monday to Friday</li>
                  <li>- 8am to 1pm on Saturday.</li>
                </ul>
                </p>
                <p>No work is planned on Sundays and public holidays.</p>
                <p>For safety reasons, some out of hours work may be carried out at night or early in the morning for work that requires calm water conditions.We would inform impacted residents before carrying out any out of hours work.</p>
                <p>Our work may be noisy at times but we would do everything we can to minimise its impact. Maximum noise levels and catchment areas have been predicted and are outlined within the REF.</p>`,
      x: 35,
      y: 96,
      env: false,
    },
    {
      img: Waterside,
      title: 'Waterside',
      caption:
        'The existing wharf and existing piles would be removed and replaced with a pontoon, gangway, covered waiting area and jetty with piles.',
      x: 58,
      y: 84,
      env: false,
    },
    {
      img: LiftAndStairs,
      title: 'Lift and Stairs',
      caption:
        'A new lift and stairs would connect the wharf at the foreshore to Lower Serpentine Road above. The lift would have weather protection at both entrances. A preferred lift wall design option will be chosen during detailed design.',
      x: 68,
      y: 55,
      env: false,
    },
    {
      img: Transport,
      title: 'Transport',
      caption: `<p>The wharf would be closed for up to six months during construction. Construction expected to start in late 2022 pending determination of the REF.</p>
                <p>During construction, customers would be able to catch the <b>265 Lane Cove to North Sydney via Greenwich</b> bus service Monday to Saturday from Lower Serpentine Road, where they can then connect with other services. The 265 bus service also connects to St Leonards Station and North Sydney Station, which is another alternative for customers during construction.</p>
                <p>The closure of Greenwich Point Wharf would not change the ferry timetable for other wharves on the F8 route. Customers would be encouraged to plan their trip by visiting <b>transportnsw.info</b> or phone Transport Info on <b>131 500</b> before starting their journey.</p>
                <p>Ferry services would resume as normal after construction.</p>
                `,
      x: 68,
      y: 34,
      env: false,
    },
  ],
  faq: [
    {
      title: 'Is it necessary to upgrade the wharf?',
      body: `As an operator of public transport under the <i>Disability Discrimination Act 1992</i> (DDA), Transport for NSW is required to upgrade the public transport precincts to ensure equitable access is provided for all customers.\n\nSome public transport stations, wharves and stops do not currently meet the requirements of the Commonwealth <i>Disability Standards for Accessible Public Transport 2002 Act</i> (DSAPT). The Standards set out minimum accessibility requirements for public transport providers and ensure that people with a disability have equivalent access to public transport services.\n\nUpgrading public transport precincts will make the public transport network accessible to people with additional mobility requirements, parents/carers with prams and customers with luggage.\n\nFor more information visit the <a href="https://www.transport.nsw.gov.au/projects/current-projects/tap-3#Sydney_Ferries_-_TAP_3_(2019-2023))">Transport Access Program</a>.`,
    },
    {
      title: 'What is the Transport Access Program?',
      body: 'This is an initiative to provide a better experience for public transport customers, Transport for NSW is in the process of improving wharves across Sydney. The aim is to upgrade these structures, so they are modern, safe and accessible. This program has most recently delivered upgraded wharves at Kissing Point, Woolwich, Parramatta, Rydalmere, Cabarita and Abbotsford.',
    },
    {
      title: 'How will this upgrade benefit us?',
      body: `The proposal would improve:<ul>
        <li>access for customers with assisted and unassisted mobility needs</li>
        <li>access for customers with prams and luggage</li>
        <li>protection from the weather</li>
        <li>seating and waiting areas</li>
        <li>safety for customers and staff</li>
        <li>ferry pick-up and drop-off</li>
        <li>pedestrian access around the wharf</li>
        <li>access for recreational berthing.
        </ul>`,
    },
    {
      title: 'How much will the upgrade cost?',
      body: "Ferry wharves at Double Bay, Darling Point and Greenwich Point are among those being made safer and accessible with a $37 million funding allocation from the NSW Government's Waterways Fund.",
    },
    {
      title: 'What is a pontoon? Why would it improve efficiency and safety at the wharf?',
      body: 'A pontoon is a floating structure that is connected to the foreshore by a platform or walkway. A floating pontoon allows for movement up and down with the tide to provide safe and level access between the ferries and wharf platform.\n\nThe size of the pontoon is determined by future patronage numbers and environmental influences and maritime activities in each location. Larger pontoons are required in locations with higher patronage numbers and choppier conditions to maintain the stability of the floating pontoon and to provide a safe platform for customers and ferries to operate.',
    },
    {
      title: 'Are other wharfs in the area being upgraded?',
      body: `Wharf interchanges across Sydney are being upgraded to improve the transport experience for customers and the community. To find out more about wharf upgrades in your area visit\n<a href="https://nswroads.work/wharf-upgrade">nswroads.work/wharf-upgrade</a>`,
    },
    {
      title: 'Why is this wharf being upgraded before others?',
      body: `The Wharf Upgrade Program has been ongoing for a number of years and has delivered multiple upgrades. This upgrade is one of the program's third package of wharves being investigated as part of the Transport Access Program. The program for the Wharf Upgrade Program is developed based on funding availability and complexity of the site.Will the new wharf be accessible for people using wheelchairs and other mobility aids?\n\nYes, the aim of the wharf upgrade is to upgrade these structures, so they are modern, safe and accessible. This aligns with the Disability Standards for Accessible Public Transport 2002 (DSAPT) and Disability (Access to Premises - Buildings) Standards (2010) (Disability Standards 2010) made under the Disability Discrimination Act 1992 (DDA), which requires all public transport infrastructure, including wharves, to have fully compliant disability access by 2022.`,
    },
    {
      title: 'Will the new wharf be accessible for people using wheelchairs and other mobility aids?',
      body: `Yes, the primary purpose of this proposal is to upgrade the wharf to meet accessibility requirements under Disability Standards for Accessible Public Transport 2002 (DSAPT) and Disability (Access to Premises – Buildings) Standards (2010) (Disability Standards 2010) made under the <i>Disability Discrimination Act 1992</i> (DDA), which requires all public transport infrastructure, including wharves, to have fully compliant disability access by 2022.`,
    },
    {
      title: 'What community consultation has been carried out?',
      body: `<p>In March 2021 we gathered feedback on the concept design. Comments received during consultation have been summarised and responded to in a community consultation report which is available on our webpage.</p><p>A Review of Environmental Factors has been prepared to assess the potential environmental impacts associated with the proposal. The Review of Environmental Factors can be found on the project <a href='/'>webpage</a>.</p>`,
    },
    {
      title: 'Will the existing wharf be maintained?',
      body: `The existing wharf structure would be removed as part of this upgrade.`,
    },
    {
      title: 'What are the next steps after feedback is received?',
      body: `<ul>
        <li>Submissions received during REF public display are considered as part of the final design process where possible.</li>
        <li>Pending determination of the Review of Environmental Factors, planning for construction will be completed.</li>
        <li>Detailed design will begin in early 2022</li>
        <li>Construction starts.</li>
      </ul>`,
    },
    {
      title: 'When would construction start?',
      body: 'Construction is expected to start mid 2022, pending the completion of the final detailed design and outcome of the Review of Environmental Factors which is currently on display for your feedback.',
    },
    {
      title: 'How long will it take for the construction to be completed?',
      body: 'Construction of the proposal would take up to six months to complete, weather and maritime conditions permitting. Construction expected to start in mid 2022 pending determination of the REF.',
    },
    {
      title: 'Will the wharf be shut down when construction starts?',
      body: `<p>The wharf would be closed for up to six months during construction. Construction expected to start in mid 2022 pending determination of the REF.</p><p>During construction, customers would be able to catch the <b>265 Lane Cove to North Sydney via Greenwich</b> bus service Monday to Saturday from Lower Serpentine Road, where they can then connect with other services. The 265 bus service also connects to St Leonards Station and North Sydney Station, which is another alternative for customers during construction.</p><p>The closure of Greenwich Point Wharf would not change the ferry timetable for other wharves on the F8 route.</p><p>Customers would be encouraged to plan their trip by visiting <a href='https://transportnsw.info'>transportnsw.info</a> or phone Transport Info on <a href="tel:131500">131 500</a> before starting their journey.</p>`,
    },
    {
      title: 'How can I contact the project team?',
      body: 'If you have any questions or would like a copy of the Review of Environmental Factors please contact our project team:\n\n<a href="mailto:WharfUpgradeProgram@transport.nsw.gov.au">WharfUpgradeProgram@transport.nsw.gov.au</a> or call <a href="tel:1800770973">1800 770 973</a> (toll free) during business hours.',
    },
    {
      title: 'Will the wharf impede my view?',
      body: `<p>The  new commuter ferry wharves have been designed to create a distinctive theme for Sydney Harbour. The design aims to unify and identify the harbour wharves and ferry commuter transport system.</p><p>The proposed wharf design minimises impact to the visual amenity in the area through the use of glass walls for wind protection. The curved roof is designed to be low profile and minimise the impact on the views to and from the water. The project's Review of Environmental Factors, which is currently on public display for your feedback, assesses the visual impact of the proposal and details how the proposal seeks to minimise any potential landscape character and visual impacts.</p>`,
    },
    {
      title: 'Can chartered vessels use the wharf to pick-up and drop-off passengers?',
      body: `<p>Current rules for recreational and commercial vessels would apply at the new wharf. Vessels would be permitted to berth for pick-up and drop-off, however they are not permitted to tie up to the wharf.</p><p>Commuter Wharves cannot be booked. To use a Commuter Wharf, a commercial vessel operator must purchase a Regular Use Permit or a Voucher pack from Transport. Timetabled services, such as ferries, have priority access to the Commuter Wharves over other commercial or recreational vessel operators. Please see a full list of Commuter Wharves included in the Wharf Access Policy.</p>`,
    },
    {
      title: 'What alternative transport options are available during the wharf upgrade?',
      body: `<p>Neighbouring wharves would remain open during the construction of the Greenwich Point Wharf upgrade.</p><p>The wharf would be closed for up to six months during construction. Construction expected to start in mid 2022 pending determination of the REF.</p><p>During construction, customers would be able to catch the 265 Lane Cove to North Sydney via Greenwich bus service Monday to Saturday from Lower Serpentine Road, where they can then connect with other services. The 265 bus service also connects to St Leonards Station and North Sydney Station, which is another alternative for customers during construction.</p><p>The closure of Greenwich Point Wharf would not change the ferry timetable for other wharves on the F8 route.</p><p>Customers would be encouraged to plan their trip by visiting <a href='https://transportnsw.info'>transportnsw.info</a> or phone Transport Info on <a href="tel:131500">131 500</a> before starting their journey.</p>`,
    },
    {
      title: 'Where will the existing bus stop be located on Lower Serpentine Road?',
      body: 'The existing bus shelter on Lower Serpentine Road would be relocated closer to the new wharf access points.',
    },
    {
      title: 'Will the existing stairs be upgraded as part of the proposal?',
      body: 'A new staircase will be installed as part of the wharf upgrade. The existing stairs that run along the shore will be replaced as part of the wharf upgrade.',
    },
  ],
  about:
    'Customers with mobility needs are currently unable to access Lower Serpentine Road from Greenwich Point Wharf unassisted.\n\nThe Review of Environmental Factors for the planned accessibility upgrade has been prepared and is on display for community feedback until 5pm on Wednesday 19 January 2022.\n\nTo learn more, visit the project page at nswroads.work/greenwichwharf',
  aboutPoints: [
    'improved access for customers with assisted and unassisted mobility needs',
    'improved access for customers with prams and luggage',
    'improved protection from the wind, rain and sun',
    'improved seating and waiting areas',
    'improved safety for customers and staff',
    'quicker and more efficient ferry pick-up and drop-off',
    'improved pedestrian access around the wharf interchange.',
  ],
  lift: false,
  have_your_say: 'https://yoursay.transport.nsw.gov.au/greenwich-point-wharf/survey_tools/feedback11',
  feedbackDate: 'Wednesday 5 May 2021',
  surveyOpen: true,
  consultationText: 'The consultation period is now open.',
  reportLink: '',
}

export default greenwichPointData
