import { FC, useState, useEffect } from 'react'
import { NavLink, Link, useLocation } from 'react-router-dom'

import { ReactComponent as Logo } from './images/Logo.svg'
import { ReactComponent as Menu } from './images/Menu.svg'
import { ReactComponent as Notification } from './images/Notification.svg'

import { ReactComponent as Home } from './images/Home.svg'
import { ReactComponent as About } from './images/About.svg'
import { ReactComponent as Feedback } from './images/Feedback.svg'
import { ReactComponent as FAQ } from './images/FAQ.svg'

import { ReactComponent as Email } from './images/Email.svg'
import { ReactComponent as Twitter } from './images/Twitter.svg'
import { ReactComponent as Facebook } from './images/Facebook.svg'
import { ReactComponent as Linkedin } from './images/Linkedin.svg'

import Data from './data/greenwich_point'
import Style from './style/header.module.sass'

const Header: FC = () => {
  const [sideMenuOpen, setSideMenuOpen] = useState(false)
  const [notificationOpen, setNotificationOpen] = useState(false)

  const toggleSideMenu = () => setSideMenuOpen((prev) => !prev)
  const toggleNotification = () => setNotificationOpen((prev) => !prev)

  const loc = useLocation()

  useEffect(() => {
    setSideMenuOpen(false)
  }, [loc])

  const surveyNewWindow = !Data.have_your_say?.toLowerCase().includes('swipengage.com') || false

  const haveYourSayNavLink = surveyNewWindow ? (
    <a href={Data.have_your_say} target='_blank' rel='noreferrer'>
      <Feedback /> Have your say
    </a>
  ) : (
    <NavLink activeClassName={Style.active} to='/have_your_say'>
      <Feedback /> Have your say
    </NavLink>
  )

  return (
    <>
      <div className={Style.header}>
        <a
          aria-label='TfNSW wharves upgrades site'
          href='https://roads-waterways.transport.nsw.gov.au/maritime/projects/ferry-wharf-upgrade-program.html'
        >
          <Logo className={Style.logo} />
        </a>
        <Link to='/'>
          <h2>Wharves Upgrade Program</h2>
        </Link>
        <button
          title='Notifications'
          className={Style.notification + (notificationOpen ? ' ' + Style.active : '')}
          onClick={toggleNotification}
        >
          <Notification />
        </button>
        <button title='Menu' className={Style.menu} onClick={toggleSideMenu}>
          <Menu />
        </button>
      </div>

      <div className={Style.sidemenu + (sideMenuOpen ? ' ' + Style.active : '')}>
        <h2>Wharves Upgrade Program</h2>
        <button className={Style.close} onClick={toggleSideMenu}>
          ✕
        </button>
        <div className={Style.container}>
          <div className={Style.links}>
            <NavLink activeClassName={Style.active} exact to='/'>
              <Home /> Home
            </NavLink>
            <NavLink activeClassName={Style.active} to='/learn_more'>
              <About /> Learn more
            </NavLink>
            {Data.surveyOpen && haveYourSayNavLink}
            <NavLink activeClassName={Style.active} to='/faq'>
              <FAQ /> FAQs
            </NavLink>
          </div>
          <div className={Style.info}>
            <p>
              If you have any questions or would like more information on the {Data.title} proposal please contact our
              project team:{' '}
              <a href='mailto:wharfupgradeprogram@transport.nsw.gov.au'>
                WharfUpgradeProgram
                <br />
                @transport.nsw.gov.au
              </a>{' '}
              or call <br />
              <a href='tel:1800770973'>1800 770 973</a> (toll free) during business hours.
            </p>
          </div>
          <div className={Style.share}>
            <p>Share via</p>
            <div className={Style.socials}>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href={
                  'mailto:?Subject=' +
                  encodeURIComponent(Data.title) +
                  "&Body=I'd%20like%20to%20share%20this%20Transport%20for%20New%20South%20Wales%20interactive%20web%20portal%20with%20you%20that%20explains%20the%20proposed%20upgrade%20of%20" +
                  encodeURIComponent(Data.wharf) +
                  ':%20(https://wharvesupgrades.mycommunityengine.com/' +
                  Data.slug +
                  ')'
                }
              >
                <Email />
              </a>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href={
                  "https://twitter.com/intent/tweet?text=I'd%20like%20to%20share%20this%20Transport%20for%20New%20South%20Wales%20interactive%20web%20portal%20with%20you%20that%20explains%20the%20proposed%20upgrade%20of%20" +
                  encodeURIComponent(Data.wharf) +
                  ':%20(https://wharvesupgrades.mycommunityengine.com/' +
                  Data.slug +
                  ')'
                }
              >
                <Twitter />
              </a>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href={
                  'https://www.facebook.com/sharer/sharer.php?u=https://wharvesupgrades.mycommunityengine.com/' +
                  Data.slug +
                  ''
                }
              >
                <Facebook />
              </a>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href={
                  'http://www.linkedin.com/shareArticle?mini=true&url=https://wharvesupgrades.mycommunityengine.com/' +
                  Data.slug +
                  "&title=I'd%20like%20to%20share%20this%20Transport%20for%20New%20South%20Wales%20interactive%20web%20portal%20with%20you%20that%20explains%20the%20proposed%20upgrade%20of%20" +
                  encodeURIComponent(Data.wharf) +
                  ':%20(https://wharvesupgrades.mycommunityengine.com/' +
                  Data.slug +
                  ')'
                }
              >
                <Linkedin />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className={Style.notificationBox + (notificationOpen ? ' ' + Style.active : '')}>
        <button title='Close' className={Style.close} onClick={() => setNotificationOpen(false)}>
          ✕
        </button>
        <p>Important Notice</p>
        {Data.surveyOpen && (
          <>
            <p>Community consultation is now open.</p>
            {surveyNewWindow ? (
              <a href={Data.have_your_say} target='_blank' rel='noreferrer'>
                <button className='btn' onClick={() => setNotificationOpen(false)}>
                  Have your say
                </button>
              </a>
            ) : (
              <Link to='/have_your_say'>
                <button className='btn' onClick={() => setNotificationOpen(false)}>
                  Have your say
                </button>
              </Link>
            )}
          </>
        )}
      </div>
    </>
  )
}

export default Header
