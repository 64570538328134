import { FC } from 'react'
import { HashRouter, Switch, Route, Redirect } from 'react-router-dom'
import Header from './Header'
import Wharf from './Wharf'
import About from './About'
import FAQ from './FAQ'
import Data from './data/greenwich_point'

import './style/main.sass'

const App: FC = () => {
  return (
    <HashRouter hashType='noslash'>
      <Header />
      <Switch>
        <Route exact path='/'>
          <Wharf />
        </Route>

        <Route exact path='/learn_more'>
          <About />
        </Route>

        {Data.surveyOpen && (
          <Route exact path='/have_your_say'>
            {Data.have_your_say && <iframe src={Data.have_your_say} title='Have your say' />}
          </Route>
        )}

        <Route exact path='/faq'>
          <FAQ />
        </Route>

        <Route path='*'>
          <Redirect to='/' />
        </Route>
      </Switch>
    </HashRouter>
  )
}

export default App
