/* eslint-disable */
import { FC, useState, useEffect, useRef, useLayoutEffect } from 'react'
import { Link } from 'react-router-dom'
import { animateScroll } from 'react-scroll'
import { ReactComponent as HelpIcon } from './images/Help.svg'
import { ReactComponent as ReturnIcon } from './images/Return.svg'
import { ReactComponent as MapIcon } from './images/Map.svg'
import { ReactComponent as Benefit } from './images/Benefit.svg'
import { ReactComponent as Photo } from './images/Photo.svg'
import { ReactComponent as Environment } from './images/Environment.svg'
import { ReactComponent as Pan } from './images/Hand Panning.svg'
import { ReactComponent as DownChevron } from './images/Down_Chevron.svg'
import Hotspot from './components/Hotspot'
import Gallery from './components/Gallery'
import Slider from './components/Slider'
import SingleImage from './components/SingleImage'
import Data from './data/greenwich_point'

import Style from './style/wharf.module.sass'

const IMAGE_AR = 4000 / Data.imageHeight
const HEADER_HEIGHT = 72
const HEADER_HEIGHT_MOBILE = 64
const SCROLL_DIFF = 100

const getImageSize = () => {
  const windowHeight =
    document.body.clientHeight - (document.body.clientWidth <= 700 ? HEADER_HEIGHT_MOBILE : HEADER_HEIGHT)
  const windowAR = document.body.clientWidth / windowHeight

  if (windowAR < IMAGE_AR) {
    // Match height
    return {
      height: windowHeight,
      width: windowHeight * IMAGE_AR,
    }
  } else {
    // Match width
    return {
      width: document.body.clientWidth,
      height: document.body.clientWidth / IMAGE_AR,
    }
  }
}

const resizePage = (scrollDiv: HTMLDivElement, isPopup: boolean) => {
  const windowHeight =
    document.body.clientHeight - (document.body.clientWidth <= 700 ? HEADER_HEIGHT_MOBILE : HEADER_HEIGHT)
  const windowAR = document.body.clientWidth / windowHeight

  let diff

  if (windowAR < IMAGE_AR) {
    diff = scrollDiv.scrollWidth - scrollDiv.clientWidth
    scrollDiv.scrollLeft = diff * 0.5
  } else {
    diff = scrollDiv.scrollHeight - scrollDiv.clientHeight
    scrollDiv.scrollTop = diff * 0.5
  }

  scrollDiv.style.overflowX = isPopup || windowAR > IMAGE_AR || diff < SCROLL_DIFF ? 'hidden' : 'auto'
  scrollDiv.style.overflowY = isPopup || windowAR < IMAGE_AR || diff < SCROLL_DIFF ? 'hidden' : 'auto'
  scrollDiv.style.cursor = diff < SCROLL_DIFF ? 'default' : 'grab'
}

const Wharf: FC = () => {
  const [index, setIndex] = useState(0)
  const [showHelp, setShowHelp] = useState(false)
  const [gallery, setGallery] = useState(false)
  const [slider, setSlider] = useState(false)
  const [singleImage, setSingleImage] = useState(false)
  const [welcome, setWelcome] = useState(false)
  // eslint-disable-next-lin
  const [imageStyle, setImageStyle] = useState(() => getImageSize())
  const page = useRef<HTMLDivElement>(null)
  const scroller = useRef<HTMLDivElement>(null)
  const info = useRef<HTMLDivElement>(null)

  const toggleHelp = () => setShowHelp((prev) => !prev)

  const onClose = () => {
    setSlider(false)
    setGallery(false)
    setSingleImage(false)
    setIndex(0)
  }

  useEffect(() => {
    const onResize = () => {
      setImageStyle(getImageSize())

      if (scroller.current !== null) resizePage(scroller.current, slider || gallery)
    }

    window.addEventListener('resize', onResize)
    window.addEventListener('orientationchange', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
      window.removeEventListener('orientationchange', onResize)
    }
  }, [gallery, slider])

  useEffect(() => {
    if (page.current !== null) {
      page.current.style.overflow = gallery || slider ? 'hidden' : 'auto'
      document.body.style.position = gallery || slider ? 'fixed' : 'initial'
      if (scroller.current !== null) resizePage(scroller.current, slider || gallery)
    }
  }, [gallery, slider])

  useLayoutEffect(() => {
    if (scroller.current !== null) resizePage(scroller.current, false)

    if (window.sessionStorage.getItem('welcome') !== 'true') setWelcome(true)
  }, [])

  const onDrag = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e.buttons === 1 && (e.target as HTMLDivElement).id === 'background') {
      if (e.currentTarget.style.overflowX !== 'hidden') e.currentTarget.scrollLeft -= e.movementX

      if (e.currentTarget.style.overflowY !== 'hidden') e.currentTarget.scrollTop -= e.movementY

      e.preventDefault()
    }
  }

  const onScrollInfo = () => {
    if (page.current !== null)
      animateScroll.scrollTo(info.current !== null ? info.current.scrollHeight : 215, {
        smooth: true,
        containerId: 'wharf',
      })
  }

  const cancelWelcome = () => {
    setWelcome(false)
    sessionStorage.setItem('welcome', 'true')
  }

  const surveyNewWindow = !Data.have_your_say?.toLowerCase().includes('swipengage.com') || false
  const link = surveyNewWindow ? (
    <a href={Data.have_your_say} target='_blank' rel='noreferrer'>
      <button className='btn'>Have your say</button>
    </a>
  ) : (
    <Link to='/have_your_say'>
      <button className='btn'>Have your say</button>
    </Link>
  )

  return (
    <div id='wharf' className={Style.wharf} ref={page}>
      <div className={Style.info} ref={info}>
        <h1>{Data.title}</h1>
        <p>{Data.info}</p>
        <div>
          {Data.learnMoreLink ? (
            <a className='btn' href={Data.learnMoreLink}>
              Learn More
            </a>
          ) : (
            <Link to='/learn_more'>
              <button className='btn'>Learn more</button>
            </Link>
          )}
          {Data.surveyOpen && link}
          {Data.reportLink && (
            <a href={Data.reportLink} target='_blank' rel='noreferrer'>
              <button className='btn'>View the report</button>
            </a>
          )}
        </div>
        <button className={Style.chevron} title='Show Info' onClick={onScrollInfo}>
          <DownChevron />
        </button>
      </div>

      <div className={Style.siteLabCanvas}>
        <canvas id='siteLabCanvas' className={Style.wharf}></canvas>
            {Data.sliders.map((s, i) => (
              <Hotspot
                key={i}
                id={i}
                hotspotType='photo'
                onClick={() => {
                  setIndex(i)
                  setSlider(true)
                }}
                x={s.x}
                y={s.y}
                count={s.options?.length}
              >
                <p>{s.title}</p>
              </Hotspot>
            ))}
            {Data.gallery.map((g, i) => {
              if (g.env)
                return (
                  <Hotspot
                    key={i}
                    id={i}
                    hotspotType='environment'
                    onClick={() => {
                      setIndex(i)
                      setGallery(true)
                    }}
                    x={g.x}
                    y={g.y}
                  >
                    <p>{g.title}</p>
                  </Hotspot>
                )
              else
                return (
                  <Hotspot
                    key={i}
                    id={i}
                    hotspotType='benefit'
                    onClick={() => {
                      setIndex(i)
                      setGallery(true)
                    }}
                    x={g.x}
                    y={g.y}
                  >
                    <p>{g.title}</p>
                  </Hotspot>
                )
            })}
      </div>

      {slider && <Slider index={index} setIndex={(i) => setIndex(i)} sliders={Data.sliders} onClose={onClose} />}
      {gallery && <Gallery index={index} setIndex={(i) => setIndex(i)} gallery={Data.gallery} onClose={onClose} />}
      {singleImage && <SingleImage image={Data.mapImage} onClose={onClose} />}

      <div className={Style.helpButtons}>
        <button title='Help' onClick={toggleHelp}>
          <HelpIcon />
        </button>
        <button title='Map' onClick={() => setSingleImage(true)}>
          <MapIcon />
        </button>
        <button title='Return' id='RetunrButton'>
          <ReturnIcon />
        </button>
      </div>

      <div className={Style.help + (showHelp ? ' ' + Style.active : '')}>
        <button title='Close' className={Style.close} onClick={toggleHelp}>
          ✕
        </button>
        <p className={Style.bold}>Note</p>
        <p>Artist impression for illustrative purposes only.</p>

        <p className={Style.bold}>How to navigate</p>
        <div className={Style.row}>
          <Pan />
          <p>Pan to look around</p>
        </div>

        <p>Find out more about the project by clicking on the hot spots.</p>

        <div className={Style.hotspotTypes}>
          <div className={Style.row}>
            <Photo />
            <p>Existing and proposed design</p>
          </div>
          <div className={Style.row}>
            <Benefit />
            <p>Design features</p>
          </div>
          {Data.gallery.some((g) => g.env) && (
            <div className={Style.row}>
              <Environment />
              <p>Review of environmental factors</p>
            </div>
          )}
        </div>
      </div>

      {welcome && (
        <div
          id='welcome'
          className={Style.welcome}
          onClick={(e) => {
            if ((e.target as HTMLDivElement).id === 'welcome') cancelWelcome()
          }}
        >
          <div className={Style.help}>
            <button title='Close' className={Style.close} onClick={cancelWelcome}>
              ✕
            </button>
            <p className={Style.bold}>Welcome!</p>
            <p className={Style.bold}>How to navigate</p>
            <div className={Style.row}>
              <Pan />
              <p>Pan to look around</p>
            </div>

            <p>Find out more about the project by clicking on the hot spots.</p>

            <div className={Style.hotspotTypes}>
              <div className={Style.row}>
                <Photo />
                <p>Existing and proposed design</p>
              </div>
              <div className={Style.row}>
                <Benefit />
                <p>Design features</p>
              </div>
              {Data.gallery.some((g) => g.env) && (
                <div className={Style.row}>
                  <Environment />
                  <p>Review of environmental factors</p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Wharf
